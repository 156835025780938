import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import ImgSemifull from '../../../components/ImgSemifull';
import ImgFit from '../../../components/ImgFit';
import Back from '../../../components/Back';
import ImgSmall from '../../../components/ImgSmall';
import ImgFull from '../../../components/ImgFull';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Flex from '../../../components/Flex';
import Iframe from '../../../components/Iframe';
import Helmet from 'react-helmet';
import Logo from './logo-nirovision.svg';
import GraphSvg from './graph.svg';
import BrandSymbol from './brand-symbol.svg';
import BrandLogotype from './brand-logotype.svg';
import BrandColor from './brand-color.svg';
import TimelineSvg from './timeline.svg';
import InsightsSvg from './insights.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 5,
  isWork: true,
  url: 'nirovision',
  title: 'Nirovision',
  subtitle: 'Identity Recognition Platform',
  path: '/work/nirovision',
  excerpt: `Identity Recognition Platform`,
};

const NiroPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro dark full bg={styles.colors.nirovision.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          <ImgSmall img={GraphSvg} />
          <p className='highlight'>Artificial Intelligence is changing the CCTV landscape.</p>
          <p>The industry is moving towards a new source of information and businesses are adopting AI technology to help them work faster and smarter. Monitoring is happening on the go, from a phone, used by everyone.</p>
        </Intro>

        <Section bg='#fff'>
          <Title1 title='The brand' />
          <p>The letters <strong>N</strong> from Niro and <strong>V</strong> from Vision connect into a geometric symbol easy to remember. The z-axis effect gives depth, and the sharp lines can morph into player control symbols like play or pause.</p>
          <ImgSmall img={BrandSymbol} alt='Nirovision symbol' />
          <p>The typography is bold and angular. The same angles used in the symbol have been used in the logotype to create a solid connection between them.</p>
          <ImgSmall img={BrandLogotype} alt='Nirovision logotype' />
          <p>The interface is blue dominated, covering different scenarios and accessibility requirements. We also introduced a wide range of secondary colours used for the custom labels.</p>
          <ImgFit img={BrandColor} alt='Colors' />
          <p>The tone and visuals we have used in presentations and trade shows are also part of our brand. We have progressively reduced the messages to the minimum letting the product express itself.</p>
          <Iframe>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/YT1dlb6kz7A" frameborder="0" allow="autoplay; encrypted-media; "></iframe>
          </Iframe>
        </Section>

        <Section dark bg='#0a0a0a'>
          <Title1 title='Video Player' />
          <p>The experience of reviewing footage feels natural. The smooth scroll allows people to reach any point of time and move with a lot of precision around any recognition.</p>
          <ImgSemifull img={data.player.childImageSharp.fluid.src} alt='iPad player' style="rounded borderdark" />
          <p className='highlight'>The player provides a contextual experience.</p>
          <p>Customers can use the player to review footage, but Nirovision also allows them to create new identities and improve existing ones from recognitions.</p>
        </Section>

        <Section dark full bg={styles.colors.nirovision.base}>
          <Title1 title='Identities' />
          <p>Identities are the core part of the product. Customers can onboard and manage identities, and everything is manageable on a big scale, making the system incredibly powerful.</p>
          <ImgSemifull img={data.identities.childImageSharp.fluid.src} alt='iPad Identities' style="rounded borderbright" />
          <p>An identity is one person saved in the system with face images associates. Optionally customers can add a name and custom metadata like observations or personal information.</p>
          <p>The system works around the labels. Customers can group identities and create alarms to receive notifications when people are recognized.</p>
          <Flex>
            <ImgFit img={data.identitiesSection.childImageSharp.fluid.src} alt='Identities section' style="rounded borderbright" />
            <ImgFit img={data.alarmsSection.childImageSharp.fluid.src} alt='Alarms section' style="rounded borderbright" />
          </Flex>
        </Section>

        <Section bg={styles.colors.white.base}>
          <Title1 title='Insights' />
          <p>The activity section has an interactive graph representing all the activity on a selected time range.</p>
          <ImgFull img={TimelineSvg} alt='Timeline' />
          <p>This section helps people to be aware of the activity of their places over time. The graph gives visual clues of who is present at their premises, and the events list shows in detail the recordings.</p>
          <ImgFit img={InsightsSvg} alt='Activity filters' />
          <p className='highlight'>The place where customers find the answers.</p>
          <p>We decided to go very granular in this section allowing users to filter the activity in multiple ways: By camera, label, identity or type. The combination of filters can give information from a single location or focus on just one person needed.</p>
          <Flex>
            <ImgFit img={data.activityFilterlabels.childImageSharp.fluid.src} alt='Identities section' style="rounded borderbright" />
            <ImgFit img={data.activityFilterIdentities.childImageSharp.fluid.src} alt='Alarms section' style="rounded borderbright" />
          </Flex>
        </Section>

        <Section dark bg='#0a0a0a'>
          <Title1 title='Multi-Platform' />
          <p>We initially focused on mobile but progressively expanded the experience to other platforms like TV, tablet and web.</p>
          <ImgSemifull img={data.tv.childImageSharp.fluid.src} alt='TV app' style=" borderdark" />
        </Section>

      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default NiroPage;

export const query = graphql`
  query GatsbyNiroQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }
    
    player: file(relativePath: { eq: "work/nirovision/player.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          src
        }
      }
    }
    playermetadata: file(relativePath: { eq: "work/nirovision/player-metadata.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    identities: file(relativePath: { eq: "work/nirovision/identities.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    identitiesSection: file(relativePath: { eq: "work/nirovision/identities-section.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    alarmsSection: file(relativePath: { eq: "work/nirovision/alarms-section.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    activityFilterIdentities: file(relativePath: { eq: "work/nirovision/filter-identities.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          src
        }
      }
    }
    activityFilterlabels: file(relativePath: { eq: "work/nirovision/filter-labels.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          src
        }
      }
    }
    tv: file(relativePath: { eq: "work/nirovision/tv.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
  
  }
`;